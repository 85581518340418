import { LaunchRounded as LaunchRoundedIcon } from '@mui/icons-material';
import { Box, Button, Collapse, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { FormikProps } from 'formik';
import { useState } from 'react';
import Form from '@/components/form';
import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import FormTextField from '@/components/form/fields/textField';
import { mutateGraphQL } from '../data';
import { FeedbackWrite } from '../data/feedback.graphql';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModalControls } from '@/providers/modal';
import { PopperWrapper } from '@/providers/modal/responsiveModal';
import { MutationFeedbackWriteArgs } from '../types/schema';

type FeedbackFormValues = {
	rating: number,
	description: string
};

export default function FeedbackModal() {
	const { user, staff } = useUserInfo();
	const [ formSubmitted, setFormSubmitted ] = useState( false );
	const { closeModal } = useModalControls();
	
	return (
		<Form<FeedbackFormValues>
			noClose
			initialValues={{
				rating     : 0,
				description: '',
			}}
			onSubmit={async ( values ) => {
				try {
					const { feedbackWrite } = await mutateGraphQL<MutationFeedbackWriteArgs>( {
						mutation : FeedbackWrite,
						variables: {
							input: {
								rating     : values.rating,
								description: values.description,
								company    : staff.company.id,
							},
						},
					} );
					if ( feedbackWrite ) {
						await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/emails/sendAppFeedback`, {
							userEmail  : user.email,
							rating     : feedbackWrite.rating,
							description: feedbackWrite.description,
						} );
					}
					
				} catch ( e ) {
					console.error( 'Failed to send feedback:', e );
				}
			}}>
			{( formik: FormikProps<FeedbackFormValues> ) => {
				const rating = formik.values.rating;
				return (
					<PopperWrapper
						title='Feedback'
						secondary='We would love to hear your feedback!'>
						{!formSubmitted ? (
							<Box sx={{ p: 1 }}>
								<Stack spacing={1}>
									<Typography variant='h3' fontWeight={500}>
										On a scale of 1 to 5, how likely are you to recommend Invoiss to a friend or
										colleague?
									</Typography>
									<Stack direction='row' spacing={1} pb={2}>
										{[ 1, 2, 3, 4, 5 ].map( ( value ) => (
											<Button
												key={value}
												color={[ 'error',
												         'warning',
												         'info',
												         'primary',
												         'success' ][ value - 1 ] as any}
												variant={rating === value ? 'contained' : 'outlined'}
												onClick={() => formik.setFieldValue( 'rating', value )}>
												{value}
											</Button>
										) )}
									</Stack>
									<Collapse in={rating > 0}>
										<Typography variant='h3' fontWeight={500}>
											{rating < 5
												? 'Your insights matter to us. How can we make Invoiss even better for you?'
												: 'We\'re thrilled you love Invoiss! What\'s your favorite feature?'}
										
										</Typography>
										<Stack pt={1}>
											<FormTextField
												multiline
												fullWidth
												name='description'
												rows={5}
											/>
										</Stack>
										<Stack sx={{ pt: 3 }}>
											<AsyncLoadingButton
												disabled={formik.values.rating === 0 || formik.values.description === ''}
												size='large'
												variant='contained'
												color='primary'
												onClick={async () => {
													await formik.submitForm();
													setFormSubmitted( true );
												}}>
												Submit Feedback
											</AsyncLoadingButton>
										</Stack>
									</Collapse>
								</Stack>
							</Box>
						) : rating < 5 ? (
							<Box sx={{ p: 1 }}>
								<Typography variant='h3' fontWeight={500} color='text.secondary'>
									We're sorry to hear that Invoiss didn't fully meet your expectations. Your detailed feedback
									is invaluable and will directly contribute to our continuous improvement. Thank you for
									helping us enhance our service.
								</Typography>
							</Box>
						) : (
							<Box sx={{ p: 1 }}>
								<Typography variant='h3' fontWeight={500} color='text.secondary'>
									Thank you for your positive feedback! It's fantastic to hear that you're enjoying Invoiss. If
									you could spare a moment, we'd love a review on the Clover App Store. It would really help us
									out!
								</Typography>
								<AsyncLoadingButton
									size='large'
									variant='contained'
									sx={{ mt: 10, width: '100%' }}
									color='primary'
									endIcon={<LaunchRoundedIcon/>}
									onClick={async () => {
										await navigator.clipboard.writeText( formik.values.description );
										window.open( 'https://www.clover.com/appmarket/apps/9TDMF3T5AN9T2', '_blank' );
										closeModal();
									}}>
									Copy and go to Clover App Store
								</AsyncLoadingButton>
							</Box>
						)}
					</PopperWrapper>
				);
			}}
		</Form>
	);
}

